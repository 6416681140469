import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import BroadcastTable from "../components/BroadcastTable";
import {
    getBroadcastList, setOpenBroadcastModal, setSingleBroadcast
} from "../broadcast.reducer";
import BroadcastModal from "./BroadcastModal";
import {RootState} from "../../../app/store";

const BroadcastPage = () => {
  const dispatch = useDispatch();

  const isBroadcastModalOpen = useSelector(
      (state: RootState) => state.broadcast.isBroadcastModalOpen ?? false
  );

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Broadcast</h4>
          <BroadcastTable />
        </Col>
      </Row>

      <BroadcastModal show={isBroadcastModalOpen} handleClose={() => {
          dispatch(setSingleBroadcast({}));
          dispatch(setOpenBroadcastModal(false))
      }} />
    </Container>
  );
};

export default BroadcastPage;
