import React from "react";
import { Modal, Container } from "react-bootstrap";
import BroadcastForm from "../components/BroadcastForm";

const BroadcastModal = ({
                                         show = false,
                                         ...props
                                     }: BroadcastModalProps) => {

    return (
        <Modal
            show={show}
            onHide={props.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title className="h6">Buat Broadcast</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <BroadcastForm />
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export interface BroadcastModalProps {
    show: boolean;
    handleClose: () => void;
}

export default BroadcastModal;
