import axios from "axios";
import {DeleteBroadcastModel, PostBroadcastModel, SetBroadcastActiveModel, UpdateBroadcastModel} from "./models";
import {endpoint} from "../../constants";

export default class BroadcastApi {
    static createBroadcast(args: PostBroadcastModel) {
        return axios
            .post<any>(
                endpoint + "/api/Broadcast/Create",
                args, {
                    headers: {
                        Authorization: `Bearer ${args.token}`,
                    },
                }
            )
            .then((res) => res.data.data);
    }

    static getBroadcastList(token: string) {
        return axios
            .get<any>(endpoint + "/api/Broadcast/GetList", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data,);
    }

    static updateBroadcast(args: UpdateBroadcastModel) {
        return axios
            .post<any>(endpoint + "/api/Broadcast/Edit", args, {
                headers: {
                    Authorization: `Bearer ${args.token}`,
                }
            })
            .then((res) => res.data.data);
    }

    static deleteBroadcast(args: DeleteBroadcastModel) {
        return axios
            .delete<any>(endpoint + "/api/Broadcast/Delete", {
                params: {
                    targetId: args.targetId,
                },
            })
            .then((res) => [
                {response: res.data.data},
                {id: args.targetId},
            ]);
    }

    static setIsBroadcastActive(args: SetBroadcastActiveModel) {
        return axios
            .put<any>(endpoint + "/api/Broadcast/SetIsActive", null, {
                headers: {
                    Authorization: `Bearer ${args.token}`,
                },
                params: {
                    targetBroadcastId: args.targetId,
                    isActive: args.isActive,
                },
            })
            .then((res) => [
                {response: res.data.data},
                {id: args.targetId},
            ]);
    }
}
