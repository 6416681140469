import React, {ChangeEvent, useEffect, useState} from "react";
import {Badge, Col, Form, ProgressBar, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {ImageUpload} from "../../../@core/models/media/ImageUpload";
import FormFile from "../../../components/FormField/FormFile";
import {checkEmptyObject} from "../../../helpers/checkEmptyObject";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {OptionModel} from "../../../@core/models/types";
import {getUserOptionList, resetUsernameOption} from "../../dataMitra/dataMitra/dataMitra.reducer";
import {UserTypeEnum} from "../../dataMitra/dataMitra/models";
import DatePicker from "react-datepicker";
import {dateSetTimeToNow} from "../../../helpers/dateManipulation";
import FileAttachmentApi from "../../media/fileAttachment/fileAttachment.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {
    createProSquadPortfolio,
    updatePortfolio,
    updateUploadProgress,
    updateUploadStatus
} from "../../proSquadPortfolio/proSquadPortfolio.reducer";
import {AttachmentModel, BroadcastTarget} from "../models";
import {BsTrash} from "react-icons/bs";
import {
    updateBroadcast,
    createBroadcast,
    resetStatus,
    getBroadcastList,
    setOpenBroadcastModal, setSingleBroadcast
} from "../broadcast.reducer";
import {customSavedOption} from "../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {defaultStyles, FileIcon} from "react-file-icon";
import {supportedImageTypes} from "../../../constants";
import AsyncSelect from "react-select/async";
import {useAsyncUserOption} from "../../../hooks/user";

type UploadProgress = {
    index: number;
    fileName: string;
    progress: number;
}

type UploadStatus = {
    index: number;
    fileName: string;
    status: ApiResponseStatus;
    message: string;
}

const BroadcastForm = ({}: BroadcastFormProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    let history = useHistory();

    const {getUserOptions} = useAsyncUserOption();

    const [isEdit, setIsEdit] = useState(false)

    const mitraUsernameOptions = useSelector((state: RootState) => state.dataMitra.usernameOptions ?? []);
    const isMitraOptionLoading = useSelector((state: RootState) => state.dataMitra.isLoading);

    const broadcast = useSelector((state: RootState) => state.broadcast.broadcast);

    const [uploadProgress, setUploadProgress] = useState<UploadProgress[]>([]);
    const [uploadStatus, setUploadStatus] = useState<UploadStatus[]>([]);

    const [savedAttachment, setSavedAttachment] = useState<AttachmentModel[]>([])
    const [filePath, setFilePath] = useState<string[]>([]);
    const [removedAttachmentIds, setRemovedAttachmentIds] = useState<string[]>([]);

    const [name, setName] = useState("");
    const [selectedUsername, setSelectedUsername] = useState<OptionModel[]>([]);
    const [validated, setValidated] = useState(false);
    const [image, setImage] = useState<ImageUpload>({} as ImageUpload);
    const [errorImage, setErrorImage] = useState<boolean>(false);
    const [classDate, setClassDate] = useState(new Date().toISOString())

    const [targetType, setTargetType] = useState("all");
    const [description, setDescription] = useState<string>("");

    const [selectedFileIndex, setSelectedFileIndex] = useState<number[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const token = useSelector((state: RootState) => state.user.token);
    const createStatus = useSelector((state: RootState) => state.broadcast.createStatus);
    const updateStatus = useSelector((state: RootState) => state.broadcast.updateStatus);
    const error = useSelector((state: RootState) => state.broadcast.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        setSelectedUsername([]);
    }, [targetType]);

    useEffect(() => {
        if (broadcast?.id !== undefined) {
            setName(broadcast.title);
            setDescription(broadcast.message);
            setClassDate(broadcast.expiredDate);
            setTargetType(broadcast.target === BroadcastTarget.Mitra ? "mitra" : broadcast.target === BroadcastTarget.Customer ? "customer" : "all");
            setSelectedUsername(broadcast.targetIds.split(",").map((item) => {
                return {
                    value: item,
                    label: item
                }
            }));
            setSavedAttachment(broadcast.attachments);
        }
    }, [broadcast]);

    useEffect(() => {
        if (createStatus !== undefined && createStatus !== ApiResponseStatus.pending) {
            MySwal.fire(customSavedOption(createStatus === ApiResponseStatus.success, error.data?.message, 'Berhasil Membuat Broadcast', 'Gagal Membuat Broadcast: ' + error.data?.message)).then(() => {
                dispatch(resetStatus());
                dispatch(getBroadcastList(token as string));
                dispatch(setSingleBroadcast({}));
                dispatch(setOpenBroadcastModal(false));
            });
        }
    }, [createStatus, token, error]);

    useEffect(() => {
        if (updateStatus !== undefined && updateStatus !== ApiResponseStatus.pending) {
            MySwal.fire(customSavedOption(updateStatus === ApiResponseStatus.success, error.data?.message, 'Berhasil Mengedit Broadcast', 'Gagal Mengedit Broadcast: ' + error.data?.message)).then(() => {
                dispatch(resetStatus());
                dispatch(getBroadcastList(token as string));
                dispatch(setSingleBroadcast({}));
                dispatch(setOpenBroadcastModal(false));
            });
        }
    }, [updateStatus, token, error]);

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onCancelHandler = () => {
        dispatch(setSingleBroadcast({}));
        dispatch(setOpenBroadcastModal(false));
    };

    const handleFileUpload = (files: any[]) => {
        console.log("files", files);
        const uploadResponse = files.map(async (attachment, index) => {
            let formData = new FormData();
            formData.append('file', attachment);
            formData.append('location', 'broadcast');
            const config = {
                // params: {
                //     location: 'marketing_event',
                // },
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // update upload progress based on index updateUploadProgress({index: index, progress: percentCompleted});
                    setUploadProgress((prev) => {
                        return [
                            ...prev.filter((item) => item.index !== index),
                            {index: index, progress: percentCompleted, fileName: attachment.name},
                        ];
                    });
                },
            };
            return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                setUploadStatus((prev) => {
                    return [
                        ...prev.filter((item) => item.index !== index),
                        {index: index, fileName: attachment.name, status: ApiResponseStatus.success, message: ""},
                    ];
                });
                return {
                    name: attachment.name,
                    path: data[0].filePath,
                }
            }).catch((e: any) => {
                setUploadStatus((prev) => {
                    return [
                        ...prev.filter((item) => item.index !== index),
                        {index: index, fileName: attachment.name, status: ApiResponseStatus.failed, message: e.message},
                    ];
                });
                return {
                    name: "",
                    path: "",
                    type: undefined
                }
            });
        });

        Promise.all(uploadResponse).then(async (attachment) => {
            setFilePath(attachment.map((file) => file.path));
            setUploadProgress([]);
        });
    };

    console.log("filePath", filePath);
    console.log("uploadProgress", uploadProgress);
    console.log("uploadStatus", uploadStatus);

    // const onOptionUsernamChangeHandler = (e: OptionModel | null) => {
    //     let newArr: OptionModel[] = [];
    //     if (e && !mitraUsername.includes(e)) {
    //         newArr = [...mitraUsername, e];
    //     } else if (e) {
    //         newArr = mitraUsername.filter((option) => option !== e);
    //     }
    //     setMitraUsername(newArr);
    // };

    const onOptionUsernamChangeHandler = (data: any) => {
        let newArr: OptionModel[] = [];
        if (!selectedUsername.includes(data)) {
            newArr = [...selectedUsername, data];
        } else {
            newArr = selectedUsername.filter((option) => option !== data);
        }
        setSelectedUsername(newArr);
    }

    const onUsernameTagCancelHandler = (option: OptionModel) => {
        if (selectedUsername.includes(option)) {
            const newArr = selectedUsername.filter((a) => a !== option);
            setSelectedUsername(newArr);
        }
    };

    const MitraTag = () => {
        return (
            <>
                {selectedUsername?.map((item: any) => (
                    <Badge pill className="grey-tag mb-2 mx-1 text-left" key={item.value}>
                        <div className="grey-tag-container">
                            <span className="grey-tag-label">{item.label}</span>
                            <span
                                className="material-icons grey-tag-close ml-1"
                                onClick={() => onUsernameTagCancelHandler(item)}
                            >cancel</span>
                        </div>
                    </Badge>
                ))}
            </>
        );
    };

    const onTargetTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        let onType = e.target.value;
        setTargetType(onType);
    };

    const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };

    const onFilePickedChange = (index: number, id?: string) => {
        if (selectedFileIndex.includes(index)) {
            setSelectedFileIndex(prevState => prevState.filter(item => item !== index));
            if (id !== undefined) {
                // remove id from selectedIds
                setSelectedIds(prevState => prevState.filter(item => item !== id));
            }
        } else {
            setSelectedFileIndex(prevState => [...prevState, index]);
            if (id !== undefined) {
                setSelectedIds(prevState => [...prevState, id]);
            }
        }
    }

    const onDeleteClicked = () => {
        const newFilePath = filePath.filter((item, index) => !selectedFileIndex.includes(index + savedAttachment.length));
        const newSavedAttachment = savedAttachment.filter((_, index) => !selectedFileIndex.includes(index));
        setFilePath(newFilePath);
        setSavedAttachment(newSavedAttachment);
        setRemovedAttachmentIds([...removedAttachmentIds, ...selectedIds]);
        setSelectedFileIndex([]);
        setSelectedIds([]);
    }

    const onSelectAllClicked = () => {
        if (selectedFileIndex.length === (savedAttachment.length + filePath.length)) {
            setSelectedFileIndex([]);
        } else {
            const _filePath = filePath.map((_, index) => index);
            const _savedAttachment = savedAttachment.map((_, index) => index + filePath.length);
            setSelectedIds(savedAttachment.map((item) => item.id));
            setSelectedFileIndex([..._filePath, ..._savedAttachment]);
        }
    }

    const mitraPromiseOptions = (inputValue: string) =>
        new Promise<OptionModel[]>((resolve) => {
            clearTimeout(timer);
            setTimer(
                setTimeout(() => {
                    resolve(getUserOptions(inputValue, UserTypeEnum.Mitra));
                }, 1000)
            );
        });

    const customerPromiseOptions = (inputValue: string) =>
        new Promise<OptionModel[]>((resolve) => {
            clearTimeout(timer);
            setTimer(
                setTimeout(() => {
                    resolve(getUserOptions(inputValue, UserTypeEnum.Customer));
                }, 1000)
            );
        });

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
                const form = e.currentTarget;
                if (!form.checkValidity()) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {

                    const req = {
                        title: name,
                        message: description,
                        expiredDate: classDate,
                        target: targetType === "mitra" ? BroadcastTarget.Mitra : targetType === "customer" ? BroadcastTarget.Customer : BroadcastTarget.All,
                        targetIdList: selectedUsername.map((item) => item.value),
                        newAttachmentList: filePath.map((item) => {
                            return {
                                fileName: item.split("/").pop() as string,
                                filePath: item
                            }
                        })
                    }

                    const id = broadcast?.id;

                    if (id !== undefined) {
                        dispatch(updateBroadcast({
                            ...req,
                            id,
                            token: token as string,
                            removedAttachmentIdList: removedAttachmentIds
                        }));
                    } else {
                        dispatch(createBroadcast({...req, token: token as string}));
                    }

                    e.preventDefault();
                    console.log("req", req);

                    // if (checkEmptyObject(image) && !isEdit) {
                    //     setErrorImage(true);
                    //     e.preventDefault();
                    // } else {
                    //     setErrorImage(false);
                    //     e.preventDefault();
                    //     setName("");
                    //     // history.replace("/mitra/professional/type/list");
                    // }
                }
                setValidated(true);
            }}>
            <Row>
                <Form.Group as={Col} md={6}>
                    <Form.Label>Judul Broadcast</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Masukkan Judul Broadcast"
                        onChange={onNameChange}
                        value={name}
                        required
                    />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-4">
                    <Form.Label>Tanggal Berakhir</Form.Label>
                    <DatePicker
                        className="form-control"
                        selected={classDate !== undefined ? new Date(classDate) : new Date()}
                        onChange={(date) => setClassDate(dateSetTimeToNow((date as Date).toISOString()) ?? new Date().toISOString())}
                    />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-4">
                    <Form.Label>Broadcast Kepada</Form.Label>
                    <Form.Control
                        as="select"
                        value={targetType}
                        onChange={onTargetTypeChange}
                        required>
                        <option value="all">Semua Pengguna</option>
                        <option value="mitra">Mitra</option>
                        <option value="customer">Customer</option>
                    </Form.Control>
                </Form.Group>

                {
                    targetType === "mitra" && (
                        <Form.Group as={Col} md={6} className="mb-4">
                            <Form.Label>
                                Mitra User
                            </Form.Label>

                            <div>
                                {/*<Select*/}
                                {/*    className="basic-single"*/}
                                {/*    classNamePrefix="select"*/}
                                {/*    placeholder={`Pilih Username ${targetType === "mitra" ? "Mitra" : "Customer"}`}*/}
                                {/*    isSearchable={true}*/}
                                {/*    name="color"*/}
                                {/*    options={mitraUsernameOptions}*/}
                                {/*    isLoading={isMitraOptionLoading}*/}
                                {/*    onChange={onOptionUsernamChangeHandler}*/}
                                {/*    hideSelectedOptions={true}*/}
                                {/*/>*/}
                                <AsyncSelect cacheOptions defaultOptions loadOptions={mitraPromiseOptions}
                                             onChange={onOptionUsernamChangeHandler}/>
                                <div className="mt-2"><MitraTag/></div>
                            </div>
                        </Form.Group>
                    )
                }

                {
                    targetType === "customer" && (
                        <Form.Group as={Col} md={6} className="mb-4">
                            <Form.Label>
                                Customer User
                            </Form.Label>

                            <div>
                                {/*<Select*/}
                                {/*    className="basic-single"*/}
                                {/*    classNamePrefix="select"*/}
                                {/*    placeholder={`Pilih Username ${targetType === "mitra" ? "Mitra" : "Customer"}`}*/}
                                {/*    isSearchable={true}*/}
                                {/*    name="color"*/}
                                {/*    options={mitraUsernameOptions}*/}
                                {/*    isLoading={isMitraOptionLoading}*/}
                                {/*    onChange={onOptionUsernamChangeHandler}*/}
                                {/*    hideSelectedOptions={true}*/}
                                {/*/>*/}
                                <AsyncSelect cacheOptions defaultOptions loadOptions={customerPromiseOptions}
                                             onChange={onOptionUsernamChangeHandler}/>
                                <div className="mt-2"><MitraTag/></div>
                            </div>
                        </Form.Group>
                    )
                }

                <Form.Group as={Col} md={12} className="mb-4">
                    <Form.Label>Pesan</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={onDescriptionChange}
                        placeholder="Masukkan isi pesan broadcast"
                        value={description}
                        required
                    />
                </Form.Group>

                <Form.Group as={Col} md={12}>
                    <FormFile
                        name="iconUrl"
                        label="Image/Attachment"
                        multiple={true}
                        defaultImage={image.fileValue}
                        handleAcceptedMultipleFiles={handleFileUpload}
                        height={200}
                        required={!isEdit}
                        onError={errorImage}
                        defaultFromApi={image.fromApi}
                    />
                </Form.Group>
                <Col md={12}>
                    <div className={"d-flex"}>
                        <div className={"d-flex flex-grow-1"}>
                            <Form.Label>
                                Uploaded Files
                            </Form.Label>
                        </div>
                        <div>
                            <button
                                className={`btn button-outline-primary mr-1 mx-md-1 my-1 my-md-0 event_button`}
                                type="button" onClick={onSelectAllClicked}>
                                {selectedFileIndex.length === filePath.length + savedAttachment.length ? "Batal Pilih Semua" : "Pilih Semua"}
                            </button>
                            <button
                                className={`btn button-outline-danger mr-1 mx-md-1 my-1 my-md-0 px-2 event_button`}
                                type="button" onClick={onDeleteClicked}>
                                <BsTrash fontSize={18}/> Hapus
                            </button>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    {
                        (uploadProgress.length === 0 && savedAttachment.length === 0 && filePath.length === 0) && (
                            <Row>

                                <Col md={12} className="text-center">
                                    <img
                                        className={`empty_portfolio_image justify-content-center`}
                                        src="https://aramata.id/assets/images/empty_portfolio.svg"
                                        width="300"
                                    />
                                </Col>
                                <Col md={12} className="text-center mt-4 mb-4">
                                    <h6 className={`empty_title`}>Belum ada file yang ditambahkan</h6>
                                </Col>
                            </Row>

                        )
                    }
                </Col>
                <Col md={12} className={'d-flex'}>
                    {
                        savedAttachment.map((item, index) => (
                            <div className={`d-flex flex-column mb-3 mx-2`}
                                 key={`localfile-${index}`} style={{width: '130px'}}>
                                <>
                                    <div className="d-flex">
                                        <div
                                            className={`m-1 position-relative result_media_item`}
                                            style={{
                                                background: `url("${item.filePath}")`,
                                            }}
                                            // onClick={() => onImageClicked(index)}
                                        >
                                            {!supportedImageTypes.includes(item.filePath?.split('.').pop() as string) && (
                                                <FileIcon
                                                    extension={item.filePath?.split('.').pop() || ''} {...(defaultStyles[item.filePath?.split('.').pop() as keyof typeof defaultStyles] || {})} />)}
                                        </div>
                                        <div
                                            className={`position-absolute media-checkbox`}
                                        >
                                            <input
                                                type="checkbox"
                                                className={`custom-control-input`}
                                                id={
                                                    index.toString()
                                                }
                                                checked={selectedFileIndex.includes(index)}
                                                onChange={() =>
                                                    onFilePickedChange(index, item.id)
                                                }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={
                                                    index.toString()
                                                }
                                            >
                                                <div
                                                    className={`check_result_file`}
                                                ></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="px-2">
                                        <div className={`text-truncate filename_label`}>{item.fileName}</div>
                                    </div>
                                </>
                                <>
                                </>
                            </div>
                        ))
                    }

                    {/* uploadProgress.some(item => item.progress < 100) && */}
                    {uploadProgress.map((item) => (
                        <div className={`d-flex flex-column mb-3 mx-2`}
                             key={`localfile-${item.index}`} style={{width: '130px'}}>
                            <>
                                <div className="d-flex">
                                    <div
                                        className={`flex align-items-center m-1 position-relative result_media_item`}
                                    >
                                        <div className={`text-truncate filename_label`}>{item.fileName}</div>
                                        <div className="spinner-border text-secondary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-2">
                                    <ProgressBar now={item.progress} label={`${item.progress} %`}/>
                                </div>
                            </>
                            <>
                            </>
                        </div>
                    ))}

                    {filePath.map((item, index) => (
                        <div className={`d-flex flex-column mb-3 mx-2`}
                             key={`localfile-${index}`} style={{width: '130px'}}>
                            <>
                                <div className="d-flex">
                                    <div
                                        className={`m-1 position-relative result_media_item`}
                                        style={{
                                            background: `url("${item}")`,
                                        }}
                                        // onClick={() => onImageClicked(index)}
                                    ></div>
                                    <div
                                        className={`position-absolute media-checkbox`}
                                    >
                                        <input
                                            type="checkbox"
                                            className={`custom-control-input`}
                                            id={
                                                (index + savedAttachment.length).toString()
                                            }
                                            checked={selectedFileIndex.includes(index + savedAttachment.length)}
                                            onChange={() =>
                                                onFilePickedChange(index + savedAttachment.length)
                                            }
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={
                                                (index + savedAttachment.length).toString()
                                            }
                                        >
                                            <div
                                                className={`check_result_file`}
                                            ></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="px-2">
                                    <div
                                        className={`text-truncate filename_label`}>{item}</div>
                                </div>
                            </>
                            <>
                            </>
                        </div>
                    ))}
                </Col>

                <div className="text-center mt-5">
                    <button
                        className="btn button-outline-primary mr-3"
                        type="reset"
                        onClick={onCancelHandler}>
                        BATALKAN
                    </button>
                    <button className="btn button-primary" type="submit">
                        {isEdit ? "EDIT BROADCAST" : "BUAT BROADCAST"}
                    </button>
                </div>
            </Row>
        </Form>
    );
};

export interface BroadcastFormProps {
}

export default BroadcastForm;
