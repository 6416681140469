import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {Link} from "react-router-dom";
import {UserModel} from "../models";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import {TableCustomStyles} from "../../../../components/DataTable/TableCustomStyles";
import {ElementPropModel} from "../../../../components/DataTable/models";
import {changeSuspendedStatus, getUserList, resetStatus} from "../dataCustomer.reducer";
import {inActiveAdmin, resetStatus as resetAdminStatus} from "../../../dataAdmin/dataAdmin.reducer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../../index";
import {UserTypeEnum} from "../../../dataMitra/dataMitra/models";
import {lastOnlineFilterOptions, MAX_PAGING_OPTIONS} from "../../../../constants";
import {FilterLastOnlineEnum} from "../../../../@core/models/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Form} from "react-bootstrap";
import moment from "moment";

const DataCustomerTable = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const customerState = useSelector(
        (state: RootState) => state.dataCustomer.userList ?? []
    );
    const rows = useSelector(
        (state: RootState) => state.dataCustomer.rows
    );
    const isLoading = useSelector(
        (state: RootState) => state.dataCustomer.isLoading
    );
    const suspendStatus = useSelector(
        (state: RootState) => state.dataCustomer.status
    );
    const suspendError = useSelector((state: RootState) => state.dataCustomer.error ?? ({} as ApiErrorResponse<any>));
    const isActiveStatus = useSelector(
        (state: RootState) => state.dataAdmin.status
    );
    const isActiveError = useSelector((state: RootState) => state.dataAdmin.error ?? ({} as ApiErrorResponse<any>));
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [lastOnlineFilter, setLastOnlineFilter] = useState<FilterLastOnlineEnum>(FilterLastOnlineEnum.All);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    // datatable components

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        if (suspendStatus !== undefined && suspendStatus !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    suspendStatus === ApiResponseStatus.success,
                    suspendError.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (suspendStatus === ApiResponseStatus.success) {
                    dispatch(getUserList({
                        userType: UserTypeEnum.Customer,
                        registerStatus: undefined,
                        name: filterText === "" ? undefined : filterText,
                        skip: 0,
                        take: rowsPerPage,
                        filterLastOnlineEnum: lastOnlineFilter
                    }));
                }
            });
        }
    }, [suspendStatus, suspendError, rowsPerPage, filterText, lastOnlineFilter]);

    useEffect(() => {
        if (isActiveStatus !== undefined && isActiveStatus !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    isActiveStatus === ApiResponseStatus.success,
                    isActiveError.data?.message
                )
            ).then(() => {
                dispatch(resetAdminStatus());
                if (isActiveStatus === ApiResponseStatus.success) {
                    dispatch(getUserList({
                        userType: UserTypeEnum.Customer,
                        registerStatus: undefined,
                        name: filterText === "" ? undefined : filterText,
                        skip: 0,
                        take: rowsPerPage,
                        filterLastOnlineEnum: lastOnlineFilter
                    }));
                }
            });
        }
    }, [isActiveStatus, isActiveError, rowsPerPage, filterText, lastOnlineFilter]);

    const onSetterFilterText = (value: string) => {
        let filterValue = value;
        setFilterText(filterValue);
        clearTimeout(timer);
        setTimer(
            setTimeout((value = filterValue) => {
                dispatch(getUserList({
                    userType: UserTypeEnum.Customer,
                    registerStatus: undefined,
                    name: value === "" ? undefined : value,
                    skip: 0,
                    take: rowsPerPage,
                    filterLastOnlineEnum: lastOnlineFilter
                }));
            }, 1000)
        );
    };

    const elementProps = {
        placeholder: "cari berdasarkan nama",
        buttonName: "",
        createUrl: "",
        subHeaderLine: true,
    } as ElementPropModel;

    const onChangeStatus = (id: string, isActive: boolean) => {
        MySwal.fire({
            text: `Apakah kamu yakin akan mengubah status akun ini?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    inActiveAdmin({
                        userId: id,
                        isActive: !isActive,
                    })
                );
            }
        });
    };

    const onChangeSuspended = (customerId: string, isSuspend: boolean) => {
        MySwal.fire({
            text: `Ubah penangguhan akun ini?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    changeSuspendedStatus({
                        id: customerId,
                        isSuspended: !isSuspend,
                    })
                );
            }
        })
    };

    const columns: TableColumn<UserModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "E-MAIL",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "TERAKHIR LOGIN",
            selector: (row) => row.lastOnline,
            sortable: true,
            format: (row: any) => row.lastOnline === "0001-01-01T00:00:00" ? "-" : moment(row.lastOnline, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
        },
        {
            name: "AKSI",
            width: "400px",
            cell: (d: UserModel) => (
                <>
                    <Link
                        to={{
                            pathname: `/customer/account/detail/${d.id}`,
                        }}
                    >
                        <button className="btn button-primary mr-2">DETAIL</button>
                    </Link>
                    <button
                        onClick={() => onChangeSuspended(d.customerId, d.isSuspended)}
                        className={`btn mr-2 ${d.isSuspended ? "btn-success" : "button-danger"} `}>
                        {d.isSuspended ? "DIIJINKAN" : "DITANGGUHKAN"}
                    </button>
                    <button
                        onClick={() => onChangeStatus(d.id, d.isActive)}
                        className={`btn ${d.isActive ? "button-danger" : "btn-success"} `}>
                        {d.isActive ? "NON AKTIFKAN" : "AKTIFKAN"}
                    </button>
                </>
            ),
        },
    ];

    // end of datatable components

    useEffect(() => {
        dispatch(getUserList({
            userType: UserTypeEnum.Customer,
            registerStatus: undefined,
            name: undefined,
            skip: 0,
            take: rowsPerPage,
            filterLastOnlineEnum: lastOnlineFilter
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastOnlineFilter]);

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        dispatch(getUserList({
            userType: UserTypeEnum.Customer,
            registerStatus: undefined,
            name: filterText === "" ? undefined : filterText,
            skip: newPerPage * (page - 1),
            take: newPerPage,
            filterLastOnlineEnum: lastOnlineFilter
        }));
    };

    const handlePageChange = (page: number) => {
        dispatch(getUserList({
            userType: UserTypeEnum.Customer,
            registerStatus: undefined,
            name: filterText === "" ? undefined : filterText,
            skip: rowsPerPage * (page - 1),
            take: rowsPerPage,
            filterLastOnlineEnum: lastOnlineFilter
        }));
    };

    const onLastOnlineFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setLastOnlineFilter(parseInt(e.target.value));
    };

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div className="w-100">
                <hr/>
                <div className="d-flex">
                    <div className="input-field-group">
                        <FontAwesomeIcon
                            className="input-field-group-icon"
                            icon={faSearch}
                            // style={{ color: "#160E25" }}
                        />
                        <input
                            id="search"
                            type="search"
                            placeholder="cari berdasarkan nama"
                            className="input-field-group-text"
                            style={{width: "18rem"}}
                            value={filterText}
                            onChange={(e) => {
                                onSetterFilterText(e.target.value);
                            }}
                        />
                    </div>
                    <Form.Control
                        as="select"
                        value={lastOnlineFilter.toString()}
                        onChange={onLastOnlineFilterChange}
                        className={'mx-2'}
                        style={{width: '150px', height: '46px'}}
                        required>
                        {
                            lastOnlineFilterOptions.map((item) => (
                                <option key={item.value} value={item.value}>{item.label}</option>
                            ))
                        }
                    </Form.Control>
                </div>
                <hr/>
            </div>
        )
    }, [filterText, lastOnlineFilter]);

    return (
        <>
            <DataTable
                columns={columns}
                data={customerState}
                pagination
                paginationServer
                paginationTotalRows={rows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noHeader
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={TableCustomStyles}
                progressPending={isLoading}
                persistTableHead
                paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
            />
        </>
    );
};

export default DataCustomerTable;
