import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { Link } from "react-router-dom";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import DataTable, {TableColumn} from "react-data-table-component";
import { ElementPropModel } from "../../../components/DataTable/models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../index";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {customSavedOption, deletedStatusOption, deletedWarnOption} from "../../../helpers/alertContent";
import {
  deleteBroadcast,
  getBroadcastList,
  resetStatus, setIsBroadcastActive,
  setOpenBroadcastModal,
  setSingleBroadcast
} from "../broadcast.reducer";
import {BroadcastModel} from "../models";
import {getReportIssueList} from "../../reportIssue/reportIssue.reducer";
import { Form } from "react-bootstrap";
import TrashImage from "../../../assets/images/alert_trash.png";

const BroadcastTable = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");

  const token = useSelector((state: RootState) => state.user.token);

  const broadcasts = useSelector(
    (state: RootState) => state.broadcast.broadcasts ?? []
  );
  const isActiveStatus = useSelector((state: RootState) => state.broadcast.isActiveStatus);
  const deleteStatus = useSelector((state: RootState) => state.broadcast.deleteStatus);
  const error = useSelector((state: RootState) => state.broadcast.error ?? ({} as ApiErrorResponse<any>));

  const filteredItems = broadcasts.filter(
    (item) =>
      item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
  );

    useEffect(() => {
      if (token) dispatch(getBroadcastList(token));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

  useEffect(() => {
    if (deleteStatus !== undefined && deleteStatus !== ApiResponseStatus.pending) {
      MySwal.fire(customSavedOption(deleteStatus === ApiResponseStatus.success, error.data?.message, 'Berhasil Menghapus Broadcast', 'Gagal Menghapus Broadcast: ' + error.data?.message)).then(() => {
        dispatch(resetStatus());
        dispatch(getBroadcastList(token as string));
      });
    }
  }, [deleteStatus, token, error]);

  useEffect(() => {
    if (isActiveStatus !== undefined && isActiveStatus !== ApiResponseStatus.pending) {
      MySwal.fire(customSavedOption(isActiveStatus === ApiResponseStatus.success, error.data?.message, 'Berhasil Mengubah Status', 'Gagal Mengubah Broadcast: ' + error.data?.message)).then(() => {
        dispatch(resetStatus());
        dispatch(getBroadcastList(token as string));
      });
    }
  }, [isActiveStatus, token, error]);

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onActiveChange = (id: string, isActive: boolean) => {
    MySwal.fire({
      title: 'Apakah kamu yakin?',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batalkan',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            setIsBroadcastActive({
              targetId: id,
              isActive: isActive,
              token: token as string,
            })
        );
      }
    });

  }

  const onDelete = (id: string) => {
    MySwal.fire(deletedWarnOption).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            deleteBroadcast({
              targetId: id,
              token: token as string,
            })
        );
      }
    });
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama",
    buttonName: "buat broadcast",
    createUrl: "",
    subHeaderLine: true,
    onButtonClicked: () => dispatch(setOpenBroadcastModal(true))
  } as ElementPropModel;

  const columns: TableColumn<BroadcastModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "JUDUL BROADCAST",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "BROADCAST KEPADA",
      selector: (row) => row.targetString,
      sortable: true,
    },
    {
      name: "PESAN",
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: "TANGGAL DIBUAT",
      selector: (row) => row.createdDate,
      format: (row: any) => row.createdDate.substr(0, 10),
      sortable: true,
    },
    {
      name: "STATUS",
      cell: (d: BroadcastModel) => (
          <>
            <Form.Check
                type="switch"
                id={d.id}
                label=""
                defaultChecked={d.isActive}
                checked={d.isActive}
                onChange={(e) => {
                  onActiveChange(d.id, e.target.checked);
                }}
            />
          </>
      ),
    },
    {
      name: "AKSI",
      cell: (d: BroadcastModel) => (
        <>
            <button className="btn button-primary mr-2" onClick={() => {
              dispatch(setSingleBroadcast(d))
              dispatch(setOpenBroadcastModal(true));
            }}>EDIT</button>
          <button
            onClick={() => onDelete(d.id)}
            className="btn button-danger"
          >
            HAPUS
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={false}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          false,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
        persistTableHead
      />
    </>
  );
};

export default BroadcastTable;
