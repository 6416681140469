import {ImageUpload} from "../../@core/models/media/ImageUpload";

export interface PostBroadcastModel {
    id?: string;
    title: string;
    message: string;
    expiredDate: string;
    target: number;
    targetIdList: string[];
    newAttachmentList: {
        id?: string;
        broadcastId?: string;
        fileName: string;
        filePath: string;
    }[];
    token: string;
}

export interface UpdateBroadcastModel {
    id?: string;
    title: string;
    message: string;
    expiredDate: string;
    target: number;
    targetIdList: string[];
    newAttachmentList: {
        id?: string;
        broadcastId?: string;
        fileName: string;
        filePath: string;
    }[];
    token: string;
    removedAttachmentIdList: string[];
}

export interface BroadcastModel {
    id: string;
    title: string;
    message: string;
    target: number;
    targetString: string;
    targetIds: string;
    expiredDate: string;
    expiredDateString: string;
    createdDate: string;
    createdDateString: string;
    isActive: boolean;
    attachments: AttachmentModel[];
}

export interface AttachmentModel {
    id: string;
    broadcastId: string;
    fileName: string;
    filePath: string;
}

export interface DeleteBroadcastModel {
    targetId: string;
    token: string;
}

export interface SetBroadcastActiveModel {
    targetId: string;
    isActive: boolean;
    token: string;
}

export enum BroadcastTarget {
    All,
    Customer,
    Mitra
}