export interface UserModel {
   name: string;
   isAdminVerified: boolean;
   id: string;
   email: string;
   emailConfirmed: boolean;
   username: string;
   submitDate: string;
   registerStatus: number;
   isActive: boolean;
   isSuspended: boolean;
   customerId: string;
   lastOnline: string;
}

export interface CustomerModel {
   id: string;
   userId: string;
   fullname: string;
   identityNumber: string;
   phoneNumber: string;
   address: string;
   bio: string;
   website: string;
   bankAccount: string;
   bankName: string;
   bankOnBehalf: string;
   isVerified: boolean;
   type: number;
   customerInfo: string;
   birthDate: string;
   customerPicture: string;
   gender: CustomerGender;
   ktpImage: string;
}

export enum CustomerGender {
   Man,
   Woman,
}

export interface ChangeSuspendedStatus {
   id: string;
   isSuspended: boolean;
}
