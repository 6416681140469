import React, {Fragment} from "react";
import Dropzone from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUpload,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import {convertBase64} from "../../helpers/Base64";
import {Form} from "react-bootstrap";
import {getMediaEndpoint} from "../../helpers/imageManipulation";

const FormFile = ({
                      defaultImage = "",
                      name = "",
                      label = "",
                      defaultFromApi = false,
                      height = 0,
                      required = false,
                      onError = false,
                      multiple = false,
                      ...props
                  }: FormFileProps) => {
    const handleAcceptedFiles = (files: any[]) => {
        let _fileFormat: string = files[0].type.split("/")[1];

        convertBase64(files[0], (result: any) => {
            console.log(result);
            let _image: string = result;
            props.handleAcceptedFiles?.(name, _fileFormat, _image);
        });
    };

    const handleAcceptedMultipleFiles = (files: any[]) => {
        props.handleAcceptedMultipleFiles?.(files);
    };

    return (
        <Form.Group>
            <Form.Label>
                {label}
                {required && onError && (
                    <Fragment>
                        <small>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                width="12"
                                style={{color: "#dc3545"}}
                                className="file-upload-icon ml-2"
                            />{" "}
                            Wajib Diisi
                        </small>
                    </Fragment>
                )}
            </Form.Label>
            <Dropzone multiple={multiple} onDrop={(acceptedFiles) => multiple ? handleAcceptedMultipleFiles(acceptedFiles) : handleAcceptedFiles(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <div
                        className="dropzone file-upload-frame w-100"
                        style={{height: height === 0 ? 200 : height}}
                    >
                        <div className="dz-message needsclick" {...getRootProps()}>
                            <input {...getInputProps()} />

                            <div className="text-center file-upload-label">
                                <div>
                                    {defaultImage !== "" && (
                                        <>
                                            <img
                                                src={
                                                    defaultFromApi
                                                        ? getMediaEndpoint(defaultImage)
                                                        : defaultImage
                                                }
                                                className="file-upload-image mb-3"
                                                style={{height: height === 0 ? 140 : height - 60}}
                                                alt="aramata"
                                            />
                                            <br/>
                                        </>
                                    )}
                                    {defaultImage === "" && (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faUpload}
                                                width="48"
                                                className="file-upload-icon mb-3"
                                            />
                                            <br/>
                                        </>
                                    )}
                                    <span>Upload File</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
        </Form.Group>
    );
};

export interface FormFileProps {
    defaultImage?: string;
    defaultFromApi?: boolean;
    name: string;
    label?: string;
    height?: number;
    required?: boolean;
    onError?: boolean;
    multiple?: boolean;
    handleAcceptedFiles?: (
        name: string,
        fileFormat: string,
        fileValue: string
    ) => void;
    handleAcceptedMultipleFiles?: (
        files: any[]
    ) => void;
}

export default FormFile;
