export type OptionModel = {
    label: string;
    value: string;
};

export type UploadProgress = {
    index: number;
    progress: number;
}

export enum DataStatus {
    pending,
    success,
    failed,
}

export enum FilterLastOnlineEnum
{
    All,
    Today,
    LastWeek,
    LastMonth,
    SixMonth
}