import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ApiErrorResponse} from "../..";
import BroadcastApi from "./broadcast.api";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import {
    PostBroadcastModel,
    BroadcastModel,
    SetBroadcastActiveModel,
    DeleteBroadcastModel,
    UpdateBroadcastModel
} from "./models";

export interface BroadcastSlice {
    name?: string;
    broadcasts?: BroadcastModel[];
    broadcast?: BroadcastModel;
    isBroadcastModalOpen?: boolean;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
    isActiveStatus?: ApiResponseStatus;
    createStatus?: ApiResponseStatus;
    updateStatus?: ApiResponseStatus;
    deleteStatus?: ApiResponseStatus;
}

export const createBroadcast = createAsyncThunk(
    "broadcast/create",
    async (args: PostBroadcastModel, {rejectWithValue}) => {
        try {
            return await BroadcastApi.createBroadcast(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getBroadcastList = createAsyncThunk(
    "broadcast/list",
    async (token: string, {rejectWithValue}) => {
        try {
            return await BroadcastApi.getBroadcastList(token);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateBroadcast = createAsyncThunk(
    "broadcast/update",
    async (args: UpdateBroadcastModel, {rejectWithValue}) => {
        try {
            return await BroadcastApi.updateBroadcast(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const deleteBroadcast = createAsyncThunk(
    "broadcast/delete",
    async (args: DeleteBroadcastModel, {rejectWithValue}) => {
        try {
            return await BroadcastApi.deleteBroadcast(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const setIsBroadcastActive = createAsyncThunk(
    "broadcast/setIsActive",
    async (args: SetBroadcastActiveModel, {rejectWithValue}) => {
        try {
            return await BroadcastApi.setIsBroadcastActive(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const broadcastSlice = createSlice({
    name: "broadcastState",
    initialState: {} as BroadcastSlice,
    reducers: {
        setFilter: (state, action) => {
            state.broadcasts = action.payload;
        },
        resetStatus: (state) => {
            state.status = ApiResponseStatus.pending;
            state.createStatus = ApiResponseStatus.pending;
            state.updateStatus = ApiResponseStatus.pending;
            state.deleteStatus = ApiResponseStatus.pending;
            state.isActiveStatus = ApiResponseStatus.pending;
        },
        setSingleBroadcast: (state, action) => {
            state.broadcast = action.payload;
        },
        setOpenBroadcastModal: (state, action) => {
            state.isBroadcastModalOpen = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createBroadcast.pending, (state) => {
            state.isLoading = true;
            state.createStatus = ApiResponseStatus.pending;
        });
        builder.addCase(createBroadcast.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.createStatus = ApiResponseStatus.success;
        });
        builder.addCase(createBroadcast.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.createStatus = ApiResponseStatus.failed;
        });
        builder.addCase(getBroadcastList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getBroadcastList.fulfilled, (state, {payload}) => {
            state.broadcasts = payload;
            state.isLoading = false;
        });
        builder.addCase(getBroadcastList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateBroadcast.pending, (state) => {
            state.isLoading = true;
            state.updateStatus = ApiResponseStatus.pending;
        });
        builder.addCase(updateBroadcast.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.updateStatus = ApiResponseStatus.success;
        });
        builder.addCase(updateBroadcast.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.updateStatus = ApiResponseStatus.failed;
        });
        builder.addCase(deleteBroadcast.pending, (state) => {
            state.isLoading = true;
            state.deleteStatus = ApiResponseStatus.pending;
        });
        builder.addCase(deleteBroadcast.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.deleteStatus = ApiResponseStatus.success;
        });
        builder.addCase(deleteBroadcast.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.deleteStatus = ApiResponseStatus.failed;
        });
        builder.addCase(setIsBroadcastActive.pending, (state) => {
            state.isLoading = true;
            state.isActiveStatus = ApiResponseStatus.pending;
        });
        builder.addCase(setIsBroadcastActive.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.isActiveStatus = ApiResponseStatus.success;
        });
        builder.addCase(setIsBroadcastActive.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.isActiveStatus = ApiResponseStatus.failed;
        });
    },
});

export const {setFilter, resetStatus, setSingleBroadcast, setOpenBroadcastModal} = broadcastSlice.actions;
export default broadcastSlice.reducer;
