// local storage key
import {FilterLastOnlineEnum} from "./@core/models/types";

export const LSK_USER_SLICE = "__aramatauser";
export const endpoint = "https://sandbox-api.aramata.id";
// export const endpoint = "https://api.aramata.id";

export const mediaEndpoint = "https://media.aramata.id";
export const mediaEndpointAlias = "media.aramata.id";
export const MAX_PAGING_OPTIONS = [2, 10, 50, 100, 200, 500, 1000];
export const supportedImageTypes = ["png", "jpeg", "jpg", "gif", "webp"]

// FilterLastOnlineEnum
export const lastOnlineFilterOptions = [
    {label: "All", value: FilterLastOnlineEnum.All},
    {label: "Today", value: FilterLastOnlineEnum.Today},
    {label: "Last Week", value: FilterLastOnlineEnum.LastWeek},
    {label: "Last Month", value: FilterLastOnlineEnum.LastMonth},
    {label: "Six Month", value: FilterLastOnlineEnum.SixMonth}
]