import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {OptionModel} from "../../@core/models/types";
import {getBlogAutoComplete} from "../../features/master/blog/blog.reducer";
import {AppDispatch} from "../../app/store";
import {getUserList} from "../../features/dataAdmin/dataAdmin.reducer";
import {UserTypeEnum} from "../../features/dataMitra/dataMitra/models";
import {getUserOptionList} from "../../features/dataMitra/dataMitra/dataMitra.reducer";

const useAsyncUserOption = () => {
    const dispatch : AppDispatch = useDispatch();

    const getUserOptions = async (inputValue: string, userType?: UserTypeEnum) => {
        const resultAction = await dispatch(getUserOptionList(
            {
                userType: userType,
                registerStatus: undefined,
                name: inputValue.toLowerCase() === "" ? "a" : inputValue.toLowerCase(),
                skip: 0,
                take: 1000
            }
        ));

        const originalPromiseResult = unwrapResult(resultAction)?.data;
        let _option = originalPromiseResult?.map((item: any) => {
            return {
                value: item.id,
                label: item.email,
            }
        });
        return _option as OptionModel[];
    };

    return {getUserOptions} as const;
};

export default useAsyncUserOption;
